/**
 * SEO component that queries for data with
 * Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/how-to/querying-data/use-static-query/
 */

import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
const { useLocation } = require("@reach/router")


function Seo({ description, title, imageUrl, children,isPreview,height,width,isOffplan,status,isNoIndexPage,newDevelopmentDetails }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            env
          }
        }
      }
    `
  )

 
  const previewValue = typeof window !=="undefined"? window.location.pathname?.includes("preview-") : false

  const metaDescription = description || site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title?site.siteMetadata?.title:"haus & haus"

  let robots = status === "Off Market" || isNoIndexPage || previewValue ? "noindex" : "all"
  if(process.env.GATSBY_SITE_ENV !== "production"||isPreview){
    robots = "none"
  }

  var { pathname } = useLocation()

  if (pathname == "/home/" || pathname == "/home") pathname = "/"

  var currentUrl = ""
  currentUrl = process.env.GATSBY_SITE_URL + pathname

  // check trailing slash
  if (!currentUrl.endsWith("/")) currentUrl = currentUrl + "/"

  //remove multiple slashes
  currentUrl = currentUrl.replace(/([^:]\/)\/+/g, "$1")

  //set lowercase url
  currentUrl = currentUrl.toLowerCase()

  const lastIndex = title?.lastIndexOf(" |");

const result = lastIndex !== -1 ? title?.substring(0, lastIndex) : title;

  const ogTitle = result?.replace(/ \|$/, '');

  const removeUrlQuery = imageUrl ? imageUrl.split('?') : ''
  const imageUrlSplit = removeUrlQuery?.length > 0 ? removeUrlQuery[0]?.split('.') : ""
  const imageType = imageUrlSplit?.length>0 ? imageUrlSplit[imageUrlSplit.length-1] : ''
  const imageFormat = imageType === 'jpg' ? "image/jpeg" : "image/png"
//  curl -A "facebookexternalhit/1.1" -IL "https://www.hausandhaus.com/properties/for-sale/in-dubai/3-bedroom-apartment-for-sale-in-mulberry-2-building-a1-dubai-hills-estate-dubai/94021/"
  return (
    <>
      <title>{isOffplan?title:defaultTitle ? `${title} ${defaultTitle}` : title}</title>
      <meta name="description" content={metaDescription} />
      <meta property="og:url" content={currentUrl} />
      <meta property="og:title" content={`${ogTitle} | haus & haus`} />
      <meta property="og:type" content="website" />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:image" content={imageUrl ? `${imageUrl}?hah=ae` : ""} />
      <meta property="og:image:secure_url" content={imageUrl ? imageUrl : ""} />
      <meta property="og:image:url" content={imageUrl ? `${imageUrl}?hah=ae` : ""} />
      <meta property="og:image:type" content={imageFormat} />
      {/* <meta property="og:image:width" content={width?width:"600"} />
      <meta property="og:image:height" content={height?height:"315"} /> */}
      <meta property="og:image:alt" content={title?.replace('|','')} />
      <meta property="og:site_name" content="haus & haus" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta property="twitter:domain" content="hausandhaus.com" />
      <meta name="twitter:creator" content={site.siteMetadata?.author || ``} />
      <meta name="twitter:title" content={`${ogTitle} | haus & haus`} />
      <meta name="twitter:description" content={metaDescription} />
      <meta name="twitter:image" content={imageUrl?imageUrl:""} />
      <meta name="twitter:url" content={currentUrl} />
      <meta name="robots" content={robots}></meta>
      {!newDevelopmentDetails &&
      <link rel="canonical" href={currentUrl}/>
      }
      {children}
    </>
  )
}

export default Seo
